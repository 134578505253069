import React from 'react'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Field, useForm, useFormState } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { required } from 'redux-form-validators'
import styled from 'styled-components'

import { partnersActions } from '@services'

import {
  accountNumberFormatter,
  bindActionToPromise,
  cancellablePromise,
  parseApiErrorMessage,
  recommendationMessages,
  renderRecommendationTextField,
  taxNumberFormatter,
} from '@helpers'
import { generateOptions } from '@oldComponents/PartnerEditor/helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { AsyncButton, Button, Collapse } from '@components/ui'
import { PartnerData } from '@oldComponents/PartnerEditor/types'
import { RenderTextField } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION } from '@constants'

import { isInvoicePartnerDetailsFilled } from './helpers'

import { formErrorMessages } from '@messages'
import partnerMessages from '@oldComponents/PartnerEditor/formMessages'
import styles, { SPACING } from '../styles'

const useStyles = makeStyles(styles)

const StyledButton = styled(Button)`
  margin-top: 5px;
`

const ButtonContainer = styled.div`
  height: 36px;
  text-align: right;
`

const StyledGrid = styled(Grid)`
  align-self: flex-end;
`

interface ControlledPartnerDetailsBlockProps {
  disabled: boolean
  isAdvancedAccountingAvailableForUser: boolean
  isEdit: boolean
  openOnPartnerNameError?: boolean
  recommendations: Recommendations | undefined
  fetchPartnerDetails: AsyncFunction<number, PartnerData>
}

function PureControlledPartnerDetailsFields({
  disabled,
  isAdvancedAccountingAvailableForUser,
  isEdit,
  openOnPartnerNameError = false,
  recommendations,
  fetchPartnerDetails,
}: ControlledPartnerDetailsBlockProps) {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { setErrorAlert } = useAlertDispatch()

  const { batch, change } = useForm()
  const { errors, submitErrors, submitFailed, values } = useFormState<InvoiceDetailsFormInitialValues>({
    subscription: { errors: true, submitErrors: true, submitFailed: true, values: true },
  })
  const cPromiseRef = useCancellablePromiseRef()
  const [isCopyDone, setCopyDone] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isCopyVisible] = React.useState(!isEdit || isInvoicePartnerDetailsFilled(values))
  const [isExpanded, setExpanded] = React.useState(
    isAdvancedAccountingAvailableForUser || !isInvoicePartnerDetailsFilled(values)
  )
  const isExpandedRef = React.useRef(isExpanded)

  React.useEffect(() => {
    const partnerNameError = (errors && errors.partner_name) || (submitErrors && submitErrors.partner_name)
    if (openOnPartnerNameError && !isExpandedRef.current && submitFailed && partnerNameError) {
      setExpanded(true)
      isExpandedRef.current = true
    }
  }, [openOnPartnerNameError, errors, submitErrors, submitFailed])

  async function copyPartnerDetails() {
    const partnerId = values.partner_id
    if (partnerId) {
      setLoading(true)
      try {
        cPromiseRef.current = cancellablePromise(fetchPartnerDetails(partnerId))
        const results = await cPromiseRef.current.promise
        setCopyDone(true)
        batch(() => {
          change('partner_account_number', results.account_number ?? '')
          change('partner_address', results.address ?? '')
          change('partner_city', results.city ?? '')
          change('partner_country', results.country ?? '')
          change('partner_name', results.name ?? '')
          change('partner_tax_number', results.tax_number ?? '')
          change('partner_zip_code', results.zip_code ?? '')
        })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          console.error(`Failed to fetch partner details for ID: ${partnerId}`)
          setErrorAlert(errorMessage)
        }
      }
      setLoading(false)
    }
  }

  return (
    <>
      <StyledButton
        onClick={() => {
          setExpanded(!isExpanded)
          isExpandedRef.current = !isExpanded
        }}
        size="small"
        type="button"
        variant="primaryText"
      >
        {isExpanded ? (
          <FormattedMessage id="form.partnerBlock.toggle.close" defaultMessage="Részletek elrejtése" />
        ) : (
          <FormattedMessage id="form.partnerBlock.toggle.open" defaultMessage="Részletek mutatása" />
        )}
      </StyledButton>
      <Collapse isExpanded={isExpanded}>
        <Grid container spacing={SPACING}>
          <Grid item xs={isCopyVisible ? 12 : 8}>
            <Field
              component={RenderTextField}
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerProviderNameLabel)}
              name="partner_name"
              required
              subscription={FIELD_SUBSCRIPTION}
              validate={required({
                message: formatMessage(formErrorMessages.required),
              })}
            />
          </Grid>
          {!isCopyVisible && (
            <StyledGrid item xs={4}>
              <ButtonContainer>
                <AsyncButton
                  loading={loading}
                  variant="primaryText"
                  size="small"
                  onClick={copyPartnerDetails}
                  disabled={isCopyDone || !values.partner_id}
                >
                  <FormattedMessage id="form.partnerBlock.copyDetails" defaultMessage="Partnertörzs másolása" />
                </AsyncButton>
              </ButtonContainer>
            </StyledGrid>
          )}
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={8}>
            <Field
              classes={classes}
              component={renderRecommendationTextField as any}
              disabled={disabled}
              format={accountNumberFormatter.format}
              label={formatMessage(partnerMessages.accountNumberLabel)}
              name="partner_account_number"
              parse={accountNumberFormatter.parse}
              options={generateOptions(
                recommendations?.bank_account ?? [],
                formatMessage(recommendationMessages.recommendationsLabel)
              )}
              positioner={accountNumberFormatter.positioner}
              subscription={FIELD_SUBSCRIPTION}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              classes={classes}
              component={renderRecommendationTextField as any}
              disabled={disabled}
              format={taxNumberFormatter.format}
              label={formatMessage(partnerMessages.taxNumberLabel)}
              name="partner_tax_number"
              parse={taxNumberFormatter.parse}
              options={generateOptions(
                recommendations?.tax_number ?? [],
                formatMessage(recommendationMessages.recommendationsLabel)
              )}
              subscription={FIELD_SUBSCRIPTION}
              positioner={taxNumberFormatter.positioner}
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <Field
              component={RenderTextField}
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerCountryLabel)}
              name="partner_country"
              subscription={FIELD_SUBSCRIPTION}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={RenderTextField}
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerZipCodeLabel)}
              name="partner_zip_code"
              subscription={FIELD_SUBSCRIPTION}
            />
          </Grid>
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <Field
              component={RenderTextField}
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerCityLabel)}
              name="partner_city"
              subscription={FIELD_SUBSCRIPTION}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={RenderTextField}
              disabled={disabled}
              label={formatMessage(partnerMessages.partnerAddressLabel)}
              name="partner_address"
              subscription={FIELD_SUBSCRIPTION}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  )
}

export const ControlledPartnerDetailsFields = connect(null, dispatch => ({
  fetchPartnerDetails: bindActionToPromise(dispatch, partnersActions.fetchPartnerDetails.request),
}))(PureControlledPartnerDetailsFields)

ControlledPartnerDetailsFields.displayName = 'ControlledPartnerDetailsFields'
// TODO DELME - FORM
