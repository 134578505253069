import React from 'react'
import PropTypes from 'prop-types'

import { InputProps, TextField, TextFieldProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController, useFormContext } from 'react-hook-form'

import { isFieldHighlighted } from '@helpers'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles)

export interface ReactHookFormTextFieldProps
  extends Pick<
    TextFieldProps,
    'multiline' | 'minRows' | 'maxRows' | 'required' | 'placeholder' | 'disabled' | 'className'
  > {
  endAdornment?: InputProps['endAdornment']
  highlighted?: boolean
  label?: React.ReactNode
  name: string
  transform?: ReactHookFormTransform
  type?: InputProps['type']
}

export function ReactHookFormTextField({
  className,
  disabled = false,
  endAdornment,
  highlighted = false,
  label,
  name,
  placeholder,
  required = false,
  transform,
  type,
  ...rest
}: ReactHookFormTextFieldProps) {
  const classes = useStyles()
  const { control } = useFormContext()
  const {
    field,
    fieldState: { error },
    formState: { isSubmitting },
  } = useController({ control, name })

  const hasError = !!error
  const isFieldDisabled = disabled || isSubmitting

  return (
    <TextField
      className={cx(className, 'form-control', { 'form-control-error': hasError })}
      error={hasError}
      fullWidth
      helperText={error?.message ?? ''}
      label={label}
      margin="normal"
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.bootstrapRoot,
          input: cx(classes.bootstrapInput, { highlighted: isFieldHighlighted(highlighted, field.value) }),
          error: classes.bootstrapError,
        },
        disabled: isFieldDisabled,
        endAdornment,
        type,
        id: name,
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.bootstrapFormLabel,
        htmlFor: name,
      }}
      placeholder={placeholder}
      required={required}
      {...field}
      {...(transform && {
        value: transform.input(field.value),
        onChange: event => {
          field.onChange(transform.output(event.target.value))
          transform.positioner?.(event, transform.output(event.target.value), transform.input(field.value))
        },
      })}
      {...rest}
    />
  )
}

ReactHookFormTextField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  highlighted: PropTypes.bool,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  transform: PropTypes.shape({
    input: PropTypes.func.isRequired,
    output: PropTypes.func.isRequired,
    positioner: PropTypes.func,
  }),
}
