import { getIn } from 'final-form'
import __find from 'lodash/find'
import __isEmpty from 'lodash/isEmpty'
import { SelectInstance } from 'react-select'

import {
  calculateGrossAndVatAmount,
  calculateNetAndVatAmount,
  calculateVatAmount,
  getDecimal,
  getDefaultExchangeRateValueByCurrency,
  getFirstDayOfMonth,
  parseApiErrorMessage,
} from '@helpers'

import { SetAlertProps } from '@contexts'

import { FieldListenerHelperProps } from '@components/forms'

import { EXCHANGE_RATE_DECIMAL_PLACES, InvoiceType } from '@constants'

import { MAIN_SUGGESTIONS } from './elements/LedgerNumberSelects/constants'
import { UpdatePartnerCalculationBaseCallback } from './elements/types'

export function serializeAssignment(
  {
    company_vat_category,
    expense_type,
    gross_amount,
    id,
    job_number,
    ledger_number,
    net_amount,
    note,
    tags,
    vat_amount,
    vat_ledger_number,
    vat,
  }: ExpenseDetailsAssignment,
  isAdvancedAccounting: boolean
) {
  return {
    expense_type,
    gross_amount: gross_amount == null ? '' : gross_amount,
    id,
    net_amount: net_amount == null ? '' : net_amount,
    tags,
    vat_amount: vat_amount == null ? '' : vat_amount,
    vat,
    ...(isAdvancedAccounting
      ? {
          company_vat_category,
          job_number,
          ledger_number,
          note,
          vat_ledger_number,
        }
      : {}),
  }
}

//* INITIALIZE VALUES
export function createExpenseDetailsFormInitialValues(
  data: ExpenseDetailsFrontendValues,
  defaultCurrency: Nullable<CommonIdAndNameType> | undefined,
  emptyAssignmentObject: ExpenseDetailsAssignment,
  isLockedForEditing = false,
  hasIntegration = false,
  isAdvancedAccounting = false
) {
  const emptyAssignments = [emptyAssignmentObject]

  // no expense details data
  if (__isEmpty(data)) {
    // TODO init form with proper values (remove type cast)
    // default currency is exist
    const values = {
      exchange_rate: '1',
      assignments: emptyAssignments,
    } as ExpenseDetailsFormInitialValues
    if (defaultCurrency) {
      values.currency = defaultCurrency.id
    }

    return values
    // expense details data came from BE
  } else {
    const {
      //* advanced accounting
      cash_account,
      delivered_at,
      job_number,
      kulcssoft_note,
      ledger_number,
      needs_review,
      reverse_tax_subject,
      review_done,
      //* common fields
      accounting_period_end,
      accounting_period_start,
      assignments = [],
      created_at,
      currency,
      due_at,
      exchange_date,
      exchange_rate,
      fulfilled_at,
      gross_amount,
      id,
      invoice_number,
      paid_through,
      partner_account_number,
      partner_address,
      partner_city,
      partner_country,
      partner_name,
      partner_tax_number,
      partner_zip_code,
      partner,
      payment_method,
      planned_payment_date,
      secondary_id,
      simple_tags,
      tags,
      vat_area,
      //* controls fields
      is_approved, // TODO should removed from initialValues
      vat_validation_disabled,
    } = data
    const initialValues: ExpenseDetailsFormInitialValues = {
      accounting_period_end,
      accounting_period_start,
      assignments,
      created_at,
      currency,
      due_at,
      exchange_date,
      exchange_rate,
      fulfilled_at,
      gross_amount,
      id,
      invoice_number,
      is_approved,
      paid_through,
      partner_account_number,
      partner_address,
      partner_city,
      partner_country,
      partner_id: partner?.id ?? null,
      partner_name,
      partner_tax_number,
      partner_zip_code,
      payment_method,
      planned_payment_date,
      secondary_id,
      simple_tags,
      tags,
      vat_area,
      vat_validation_disabled,
      ...(planned_payment_date != null ? { reschedule_payment: true } : {}),
      ...(isAdvancedAccounting
        ? {
            cash_account,
            delivered_at,
            job_number,
            kulcssoft_note,
            ledger_number,
            needs_review,
            reverse_tax_subject,
            review_done,
          }
        : {
            need_accounting_period: accounting_period_start != null,
          }),
    }

    if (!isLockedForEditing && !hasIntegration && data?.exchange_rate == null) {
      initialValues.exchange_rate = getDefaultExchangeRateValueByCurrency({
        exchangeRate: data.exchange_rate,
        currency,
        defaultCurrency: defaultCurrency?.id,
      })
    }

    const initialAssignments = assignments.length ? assignments : emptyAssignments
    initialValues.assignments = initialAssignments.map(assignment =>
      serializeAssignment(assignment, isAdvancedAccounting)
    )

    return initialValues
  }
}

//* QUICK HELPERS
// update select
function selectOption(ref: SelectInstance, option: object) {
  ref.selectOption(option)
}

type VatLabel = CompanyVatType & {
  label: string
}

export function createLabelForCompanyVats(vats: CompanyVatType[]): VatLabel[] {
  return vats.map(vat => ({ ...vat, label: vat.name || `${vat.percent}%` }))
}

//* CALCULATION CALLBACKS
// calculates the vat amount based on current gross and net amounts
function calculateVatAmountCallback(
  field: string,
  props: FieldListenerHelperProps<{ currencyOptions: CommonIdAndNameType[]; calculationBase: CalculationBase }>
) {
  const {
    calculationBase,
    form: { change },
    values,
    input: { value },
  } = props
  const grossAmount = calculationBase === 'net_amount' ? value : getIn(values, `${field}.gross_amount`)
  const netAmount = calculationBase === 'gross_amount' ? value : getIn(values, `${field}.net_amount`)
  const vatAmount = calculateVatAmount(grossAmount, netAmount)

  change(`${field}.vat_amount`, vatAmount)
}

// calculate the correct net and vat amount when gross is set or changing currency
function calculateNetAndVatAmountCallback(field: string, props: FieldListenerHelperProps<{ vatOptions: VatLabel[] }>) {
  const {
    values: { currency },
    values,
    form: { batch, change },
    vatOptions,
    input: { value: grossAmount },
  } = props
  const vat = getIn(values, `${field}.vat`)

  if (vat) {
    const percent = __find(vatOptions, ['id', vat])?.percent ?? null
    const amounts = calculateNetAndVatAmount(grossAmount, percent, currency)

    batch(() => {
      change(`${field}.net_amount`, amounts.net_amount)
      change(`${field}.vat_amount`, amounts.vat_amount)
    })
  }
}

// calculate the correct gross and vat amount when net is set
function calculateGrossAndVatAmountCallback(
  field: string,
  props: FieldListenerHelperProps<{ vatOptions: VatLabel[] }>
) {
  const {
    values: { currency },
    values,
    form: { batch, change },
    vatOptions,
    input: { value: netAmount },
  } = props
  const vat = getIn(values, `${field}.vat`)

  if (vat) {
    const percent = __find(vatOptions, ['id', vat])?.percent ?? null
    const amounts = calculateGrossAndVatAmount(netAmount, percent, currency)

    batch(() => {
      change(`${field}.gross_amount`, amounts.gross_amount)
      change(`${field}.vat_amount`, amounts.vat_amount)
    })
  }
}

interface CreateExpenseOrRevenueTypeHandlerProps {
  company: number
  createNewType: CreateCommonIdAndNameTypeCallback
  creatingNewType: Record<string, boolean>
  key: string
  newOption: { name: string; [key: string]: unknown }
  setErrorAlert: SetAlertProps
  selectRef: any
  setNewType: (payload: Record<string, boolean>) => void
}
//* ONCHANGE HANDLERS
// create a new expense or income type (newOption, selectRef) =>
export function onCreateExpenseOrRevenueTypeHandler({
  company,
  createNewType,
  creatingNewType,
  key,
  newOption,
  selectRef,
  setErrorAlert,
  setNewType,
}: CreateExpenseOrRevenueTypeHandlerProps) {
  setNewType({ ...creatingNewType, [key]: true })
  // call action
  createNewType({ name: newOption.name, company })
    .then(response => {
      const newType = {
        id: response.id,
        name: response.name,
      }
      // select it
      selectOption(selectRef, newType)
    })
    .catch(error => {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setErrorAlert(errorMessage)
      }
    })
    .finally(() => {
      setNewType({ ...creatingNewType, [key]: false })
    })
}

interface CreateTagHandlerProps {
  company: number
  createNewTag: CreateCommonIdAndNameTypeCallback
  setNewTag: (payload: boolean) => void
  newOption: { name: string }
  selectRef: any
  setErrorAlert: SetAlertProps
}

// create a new tag
export function onCreateTagHandler({
  company,
  createNewTag,
  setNewTag,
  newOption,
  selectRef,
  setErrorAlert,
}: CreateTagHandlerProps) {
  setNewTag(true)
  // call action
  createNewTag({ ...newOption, company })
    .then(response => {
      const newTag = {
        id: response.id,
        name: response.name,
      }
      // select it
      selectOption(selectRef, newTag)
    })
    .catch(error => {
      const errorMessage =
        typeof error === 'string' ? error : error instanceof Error ? error.message : 'Unexpected error'
      // display error in snackalert
      setErrorAlert(errorMessage)
    })
    .finally(() => {
      setNewTag(false)
    })
}

interface CreatePaidThroughHandlerProps {
  company: number
  createNewPaidThrough: CreateCommonIdAndNameTypeCallback
  setNewPaidThrough: (payload: boolean) => void
  newOption: { name: string }
  selectRef: any
}

// create a new paid through
export function onCreatePaidThroughHandler({
  company,
  createNewPaidThrough,
  setNewPaidThrough,
  newOption,
  selectRef,
}: CreatePaidThroughHandlerProps) {
  setNewPaidThrough(true)
  // call action
  createNewPaidThrough({ ...newOption, company })
    .then(response => {
      // select it
      selectOption(selectRef, {
        id: response.id,
        name: response.name,
      })
    })
    .catch(error => {
      // TODO somehow should display it for user
      // eslint-disable-next-line no-console
      console.error('Create new paid through is failed:\n', error)
    })
    .finally(() => {
      setNewPaidThrough(false)
    })
}

interface ChangeCalculationBaseHandlerProps {
  calculation_base: CalculationBase
  calculationBase: CalculationBase
  company: number
  invoiceType: InvoiceType
  partner_id: number
  updatePartnerCalculationBase: UpdatePartnerCalculationBaseCallback
}

// Update the calculation base change (gross or net)
export function onChangeCalculationBaseHandler({
  calculation_base,
  calculationBase,
  company,
  invoiceType,
  partner_id,
  updatePartnerCalculationBase,
}: ChangeCalculationBaseHandlerProps) {
  if (calculationBase !== calculation_base) {
    if (partner_id) {
      // BE save
      updatePartnerCalculationBase({
        calculation_base,
        company,
        id: partner_id,
        invoiceType,
      })
    }
  }
}

// when changing partner update details based on recommendations - tags, vat area, payment method, or assignments
export function onChangePartnerHandler({
  company,
  emptyAssignment,
  form: { change, batch },
  initialValues: { id },
  input: { value },
  updateFormWithRecommendation,
  isAdvancedAccountingAvailableForUser = false,
  invoiceType,
}: FieldListenerHelperProps<{
  company: number
  emptyAssignment: IncomeDetailsAssignment | ExpenseDetailsAssignment
  initialValues: IncomeDetailsFormInitialValues | ExpenseDetailsFormInitialValues
  invoiceType: InvoiceType
  updateFormWithRecommendation: AsyncFunction<
    { partner: number; id?: ItemIdType; company: number },
    FormRecommendationsData
  >
  isAdvancedAccountingAvailableForUser?: boolean
}>) {
  if (value) {
    updateFormWithRecommendation({ partner: value, id, company })
      .then(({ cash_account, tags, vat_area, payment_method, assignments }) => {
        // https://final-form.org/docs/final-form/types/FormApi
        // should use form.batch(() => { ... }) to collect all update into single one
        batch(() => {
          if (tags !== undefined) {
            change('tags', tags) // do not notify listeners
          }
          if (vat_area !== undefined) {
            change('vat_area', vat_area) // do not notify listeners
          }
          if (payment_method !== undefined) {
            change('payment_method', payment_method) // do not notify listeners
          }
          if (invoiceType === InvoiceType.EXPENSE && assignments) {
            // TODO for income invoice assignments does not work with expense_type (BE response)
            const newValues = assignments.map(({ vat_amount, gross_amount, net_amount, ...rest }) =>
              serializeAssignment(
                {
                  ...emptyAssignment,
                  ...rest,
                  vat_amount: vat_amount != null ? getDecimal(vat_amount) : vat_amount,
                  gross_amount: gross_amount != null ? getDecimal(gross_amount) : gross_amount,
                  net_amount: net_amount != null ? getDecimal(net_amount) : net_amount,
                },
                isAdvancedAccountingAvailableForUser
              )
            )
            change('assignments', newValues)
          }
          if (isAdvancedAccountingAvailableForUser && cash_account != null) {
            change('cash_account', cash_account) // do not notify listeners
          }
        }) // notify all listeners at once
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error)
      })
  }
}

export function onChangeFulfilledAtBasePermissionHandler({
  form: { batch, change },
  input: { value },
  values: { created_at, due_at, payment_method },
}: FieldListenerHelperProps) {
  // in base subscription plan due_at must be filled by fulfilled_at when it is empty
  // console.log('base helper')
  //! only in expense details form

  // https://final-form.org/docs/final-form/types/FormApi
  // should use form.batch(() => { ... }) to collect all update into single one
  batch(() => {
    if (['cash', 'card'].includes(payment_method) && !created_at) {
      change('created_at', value)
    }
    // update with all payment_method
    if (!due_at) {
      change('due_at', value)
    }
  })
}

export function onChangeFulfilledAtHandler({
  form: { batch, change },
  input: { value },
  invoiceType,
  values: { created_at, due_at, issued_at, payment_method },
}: FieldListenerHelperProps<{ invoiceType: 'expense' | 'revenue' }>) {
  // prefill "created_at" / "issued_at" and "due_at" fields with value of "fulfilled_at"
  // when payment_method is "cash" or "card" and those fields have no value yet
  //! expenses have created_at field and incomes have issued_at field!
  // console.log('finance helper')
  if (['cash', 'card'].includes(payment_method)) {
    // https://final-form.org/docs/final-form/types/FormApi
    // should use form.batch(() => { ... }) to collect all update into single one
    batch(() => {
      if (invoiceType === 'expense' && !created_at) {
        change('created_at', value)
      }
      if (invoiceType === 'revenue' && !issued_at) {
        change('issued_at', value)
      }
      if (!due_at) {
        change('due_at', value)
      }
    })
  }
}

// If currency is set to default, set hidden fields to default value and calculate the assignment amounts again
// to ensure correct amounts
export function onChangeCurrencyHandler({
  defaultCurrencyId,
  form: { batch, change },
  input: { value },
}: FieldListenerHelperProps<{ defaultCurrencyId: number }>) {
  // it set to 1, when select default_currency (HUF)
  if (value === defaultCurrencyId) {
    // clear exchange_rate when select HUF
    batch(() => {
      change(
        'exchange_rate',
        getDecimal(1, {
          maximumFractionDigits: EXCHANGE_RATE_DECIMAL_PLACES,
          minimumFractionDigits: 0,
        })
      )
      change('exchange_date', null)
    })
  }
}

export function onChangeGrossAmountHandler(
  props: FieldListenerHelperProps<{
    currencyOptions: CommonIdAndNameType[]
    vatOptions: VatLabel[]
  }>
) {
  const {
    form: { change },
    input: { value },
    values: { assignments },
  } = props
  if (
    Object.prototype.toString.call(assignments) === '[object Array]' &&
    assignments.length === 1 &&
    ['', '0', '0.00', null, undefined, 0].includes(assignments[0].gross_amount)
  ) {
    change('assignments[0].gross_amount', value)
    calculateNetAndVatAmountCallback('assignments[0]', props)
  }
}

export function onChangeAssignmentGrossAmount(
  props: FieldListenerHelperProps<{
    calculationBase: CalculationBase
    currencyOptions: CommonIdAndNameType[]
    field: string
    vatOptions: VatLabel[]
  }>
) {
  const { calculationBase, field } = props

  if (calculationBase === 'gross_amount') {
    calculateNetAndVatAmountCallback(field, props)
  } else {
    calculateVatAmountCallback(field, props) // calculate only when field visible for user
  }
}

// calculate new gross or net amount on vat change
export function onChangeAssignmentVatAmount({
  calculationBase,
  field,
  form: { batch, change },
  values,
  values: { currency },
  vatOptions,
  input: { value },
}: FieldListenerHelperProps<{
  calculationBase: CalculationBase
  field: string
  vatOptions: VatLabel[]
}>) {
  const percent = __find(vatOptions, ['id', value])?.percent ?? null
  const grossAmount = getIn(values, `${field}.gross_amount`)
  const netAmount = getIn(values, `${field}.net_amount`)
  const vatAmount = getIn(values, `${field}.vat_amount`)
  // NOTE need fallback when partner calculationBase is net_amount and netAmount is ''
  // (add new assignment result with calculated gross_amount, empty net and vat amount)
  const fallbackToGrossAmountBasedCalculation = netAmount === '' && vatAmount === '' && grossAmount !== ''
  if (calculationBase === 'gross_amount' || fallbackToGrossAmountBasedCalculation) {
    const amounts = calculateNetAndVatAmount(grossAmount, percent, currency)

    batch(() => {
      change(`${field}.net_amount`, amounts.net_amount)
      change(`${field}.vat_amount`, amounts.vat_amount)
    })
  } else {
    // calculationBase === 'net_amount'
    const amounts = calculateGrossAndVatAmount(netAmount, percent, currency)

    batch(() => {
      change(`${field}.gross_amount`, amounts.gross_amount)
      change(`${field}.vat_amount`, amounts.vat_amount)
    })
  }
}

export function onChangeAssignmentNetAmount(
  props: FieldListenerHelperProps<{
    calculationBase: CalculationBase
    currencyOptions: CommonIdAndNameType[]
    field: string
    vatOptions: VatLabel[]
  }>
) {
  const { calculationBase, field } = props

  if (calculationBase === 'net_amount') {
    calculateGrossAndVatAmountCallback(field, props)
  } else {
    calculateVatAmountCallback(field, props) // calculate only when field visible for user
  }
}

export function onChangeAccountingPeriodEndHandler({
  form: { change },
  input: { value },
  meta: { valid },
  startName,
  values,
}: FieldListenerHelperProps<{ startName: string }>) {
  const startValue = getIn(values, startName)
  // fill accounting_period_start field when left empty and accounting_period_end field filled
  if (!startValue && value && valid) {
    try {
      const populatedStartValue = getFirstDayOfMonth(value)
      change(startName, populatedStartValue)
    } catch (err) {
      // silently handle error
    }
  }
}

export function onChangeVatAreaHandler({
  form: { change },
  input: { value: vatArea },
  invoiceType,
  isLedgerNumberFieldDisabled,
}: FieldListenerHelperProps<{ invoiceType: InvoiceType; isLedgerNumberFieldDisabled: boolean }>) {
  const suggestion = MAIN_SUGGESTIONS[invoiceType][vatArea] ?? null

  if (suggestion != null && !isLedgerNumberFieldDisabled) {
    change('ledger_number', suggestion)
  }
}
