import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { GroupBase, SelectInstance } from 'react-select'

import { dashboardActions } from '@services'

import { bindActionToPromise, checkEmptyValues, isAdvancedAccountingAvailable, recommendationMessages } from '@helpers'
import { onCreateTagHandler } from '@oldComponents/forms/DetailsForm/helpers'

import { useAlertDispatch } from '@contexts'

import {
  AccountingPeriodFields,
  ButtonsContainerDiv,
  ControlledPartnerDetailsFields,
  PartnerSearchField,
  ReactHookFormExchangeRateFields,
  TagIconContainerDiv,
} from '@components/forms/elements'
import {
  Button,
  FormBlock,
  FormBlockTitle,
  InfoIcon,
  ReactHookForm,
  ReactHookFormAmountField,
  ReactHookFormDateField,
  ReactHookFormError,
  ReactHookFormSelectField,
  ReactHookFormSubmitButton,
} from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

import { COMPANY_USER_FEATURE_PERMISSION_MAPPER as FEATURE_PERMISSIONS } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { IncomeManualFormProps, IncomeManualFormValues } from './types'
import { useFormInitialValues } from './useFormInitialValues'
import useValidationSchema from './useValidationSchema'

import { formSelectMessages } from '@messages'
import messages from '@components/forms/messages'
import { SPACING } from '@oldComponents/forms/DetailsForm/styles'

function PureIncomeManualForm({
  company,
  createNewTag,
  currencyOptions,
  defaultCurrencyId,
  deleteIncome,
  incomeType,
  invoiceDetails,
  invoiceDetails: { id: invoiceId, user_updated: lastUpdated, partner: partnerDefaultValue },
  isAdvancedAccountingAvailableForUser,
  isEditDisabled,
  isTagsDisabledForUser,
  onCreatePartner,
  onSubmit,
  onSubmitSuccess,
  preventSubmitSuccess,
  readOnly,
  recommendations,
  skipUnsavedChanges,
  tagOptions,
  uploading,
}: IncomeManualFormProps) {
  const { formatMessage } = useIntl()
  const [creatingNewTag, setNewTag] = React.useState(false)
  const { setErrorAlert } = useAlertDispatch()
  const validationSchema = useValidationSchema(defaultCurrencyId)
  const initialValues = useFormInitialValues(invoiceDetails, defaultCurrencyId, incomeType)

  const isAllFieldDisabled = readOnly || isEditDisabled || uploading

  const handleCreateTag = React.useCallback(
    (newOption: Tag, selectRef: SelectInstance<Tag, boolean, GroupBase<Tag>>) => {
      onCreateTagHandler({
        company,
        createNewTag,
        newOption,
        selectRef,
        setErrorAlert,
        setNewTag,
      })
    },
    [company, createNewTag, setErrorAlert]
  )

  const recommendationConfig = React.useMemo(
    () => ({
      options: recommendations?.partner ?? [],
      texts: {
        recommendationsLabel: formatMessage(recommendationMessages.recommendationsLabel),
        optionsLabel: formatMessage(recommendationMessages.optionsLabel),
        newOptionPrefix: formatMessage(recommendationMessages.newOptionPrefix),
      },
    }),
    [recommendations?.partner, formatMessage]
  )

  const handleSubmit = React.useCallback(
    ({ need_accounting_period, ...values }: IncomeManualFormValues) => {
      // keep empty values compared to initialValues
      const data = checkEmptyValues(values, initialValues) as IncomeDetailsFormInitialValues

      // copy net_amount to gross_amount
      const extendedValues = {
        ...data,
        gross_amount: data.net_amount,
        accounting_period_start: need_accounting_period ? data.accounting_period_start : null,
        accounting_period_end: need_accounting_period ? data.accounting_period_end : null,
      }

      return onSubmit(extendedValues)
    },
    [initialValues, onSubmit]
  )

  return (
    <ReactHookForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSubmitSuccess={onSubmitSuccess}
      preventSubmitSuccess={preventSubmitSuccess}
      skipUnsavedChanges={skipUnsavedChanges}
      validationSchema={validationSchema}
    >
      <FormBlock>
        <Grid container spacing={SPACING}>
          <Grid item xs={6}>
            <ReactHookFormSelectField
              disabled={isTagsDisabledForUser || readOnly || uploading}
              isClearable
              isLoading={creatingNewTag}
              isMulti
              label={formatMessage(messages.itemSimpleTagsLabel)}
              name="simple_tags"
              onCreate={handleCreateTag}
              onEmptyCreateText={formatMessage(formSelectMessages.tagsEmptyCreateText)}
              options={tagOptions}
            />
          </Grid>
          <Grid item xs={6}>
            <ReactHookFormSelectField
              disabled={isTagsDisabledForUser || readOnly || uploading}
              name="tags"
              label={
                <LightTooltip title={formatMessage(messages.smartTagTooltip)} placement="top">
                  <TagIconContainerDiv>
                    {formatMessage(messages.itemTagsLabel)}
                    <InfoIcon size={16} />
                  </TagIconContainerDiv>
                </LightTooltip>
              }
              options={tagOptions}
              onCreate={handleCreateTag}
              isLoading={creatingNewTag}
              isMulti
              isClearable
              onEmptyCreateText={formatMessage(formSelectMessages.tagsEmptyCreateText)}
              selectVariant="purple"
            />
          </Grid>
        </Grid>
      </FormBlock>
      <FormBlock>
        <FormBlockTitle>
          <FormattedMessage id="incomeManual.heading.partner" defaultMessage="Partner" />
        </FormBlockTitle>
        <Grid container spacing={SPACING}>
          <Grid item xs={12}>
            <PartnerSearchField
              defaultValue={partnerDefaultValue}
              disabled={isAllFieldDisabled}
              label={formatMessage(messages.partnerNameLabel)}
              name="partner_id"
              onCreate={onCreatePartner}
              recommendationConfig={recommendationConfig}
            />
          </Grid>
        </Grid>
        <ControlledPartnerDetailsFields
          disabled={isAllFieldDisabled}
          isAdvancedAccountingAvailableForUser={isAdvancedAccountingAvailableForUser}
          isEdit={Boolean(invoiceId)}
          recommendations={recommendations}
        />
      </FormBlock>
      <FormBlock>
        <FormBlockTitle>
          <FormattedMessage id="incomeManual.heading.dates" defaultMessage="Dátumok" />
        </FormBlockTitle>
        <Grid container spacing={SPACING}>
          <Grid item xs={4}>
            <ReactHookFormDateField
              disabled={isAllFieldDisabled}
              label={formatMessage(messages.fulfilledDateLabel)}
              name="fulfilled_at"
              placeholder={formatMessage(messages.dateFieldPlaceholder)}
              required
            />
          </Grid>
        </Grid>
        <AccountingPeriodFields disabled={readOnly || uploading} />
      </FormBlock>
      <FormBlock>
        <FormBlockTitle>
          <FormattedMessage id="incomeManual.heading.netAmount" defaultMessage="Nettó összeg" />
        </FormBlockTitle>
        <Grid container spacing={SPACING}>
          <Grid item xs={4}>
            <ReactHookFormSelectField
              disabled={isAllFieldDisabled}
              label={formatMessage(messages.currencyLabel)}
              labelKey="name"
              name="currency"
              options={currencyOptions}
              valueKey="id"
              required
            />
          </Grid>
          <ReactHookFormExchangeRateFields
            defaultCurrencyId={defaultCurrencyId}
            disabled={isAllFieldDisabled}
            invoiceDetailsId={invoiceId}
          />
        </Grid>
        <Grid container spacing={SPACING}>
          <Grid item xs={4}>
            <ReactHookFormAmountField
              disabled={isAllFieldDisabled}
              label={formatMessage(messages.totalCostLabel)}
              name="net_amount"
              required
            />
          </Grid>
        </Grid>
      </FormBlock>
      <ReactHookFormError hideIcon />
      <ButtonsContainerDiv>
        <ReactHookFormSubmitButton
          hasDisabledState
          initialValues={initialValues}
          isCreateOnly={!invoiceId}
          lastUpdated={lastUpdated}
          needConfirmOnReset
        />
        {!isEditDisabled && Boolean(invoiceId) && (
          <Button variant="secondaryContained" onClick={deleteIncome}>
            <FormattedMessage id="buttons.incomeDetails.removeIncome" defaultMessage="Bevétel törlése" />
          </Button>
        )}
      </ButtonsContainerDiv>
    </ReactHookForm>
  )
}

PureIncomeManualForm.propTypes = {
  company: PropTypes.number.isRequired,
  createNewTag: PropTypes.func.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  defaultCurrencyId: PropTypes.number.isRequired,
  deleteIncome: PropTypes.func.isRequired,
  incomeType: PropTypes.number.isRequired,
  invoiceDetails: PropTypes.object.isRequired as React.Validator<IncomeDetailsFrontendValues>,
  isAdvancedAccountingAvailableForUser: PropTypes.bool.isRequired,
  isEditDisabled: PropTypes.bool.isRequired,
  isTagsDisabledForUser: PropTypes.bool.isRequired,
  onCreatePartner: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  preventSubmitSuccess: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  recommendations: PropTypes.shape({
    bank_account: PropTypes.arrayOf(PropTypes.string.isRequired),
    partner: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        isExist: PropTypes.bool,
        name: PropTypes.string.isRequired,
      }).isRequired
    ),
    tax_number: PropTypes.arrayOf(PropTypes.string.isRequired),
  }) as React.Validator<IncomeManualFormProps['recommendations']>,
  skipUnsavedChanges: PropTypes.bool.isRequired,
  tagOptions: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  uploading: PropTypes.bool.isRequired,
}

export const IncomeManualForm = connect(
  (state: Store) => ({
    company: state.auth.company.data.id,
    currencyOptions: state.dashboard.common.currencies,
    defaultCurrencyId: state.auth.company.data.default_currency,
    invoiceDetails: state.income.details.data,
    isAdvancedAccountingAvailableForUser: isAdvancedAccountingAvailable(state),
    isTagsDisabledForUser: permissionDeniedForUser(state, FEATURE_PERMISSIONS.tag),
    tagOptions: state.dashboard.tags.data,
    uploading: state.income.details.uploading,
  }),
  dispatch => ({
    createNewTag: bindActionToPromise(dispatch, dashboardActions.createTag.request),
  })
)(PureIncomeManualForm)

IncomeManualForm.displayName = 'IncomeManualForm'
