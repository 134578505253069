import __isEmpty from 'lodash/isEmpty'
import __mapValues from 'lodash/mapValues'

import { getDecimal, getDefaultExchangeRateValueByCurrency } from '@helpers'

import { EMPTY_INCOME_ASSIGNMENT, EMPTY_INCOME_VALUES } from '@constants'

// copy from BE
export const INCOME_TYPE_INVOICE = 1
export const INCOME_TYPE_AID = 2
export const INCOME_TYPE_COMPENSATION = 3
export const INCOME_TYPE_SEVERANCE_PAY = 4
export const INCOME_TYPE_DISCOUNT = 5
export const INCOME_TYPE_CASH_REGISTER_VOUCHER = 6

function getCompanyVatsAssignments(companyVats: CompanyVatType[]) {
  return companyVats
    .filter(vat => vat.cash_register_voucher_visibility && vat.percent !== null) // percent null is mixed built in VAT
    .map(vat => ({
      ...EMPTY_INCOME_ASSIGNMENT,
      vat: vat.id,
      gross_amount: getDecimal(0), // add default 0 to every vat
      net_amount: getDecimal(0), // add default 0 to every vat
      vat_amount: getDecimal(0), // add default 0 to every vat
    }))
}

//* INITIALIZE VALUES
interface IncomeCreateInitialValuesProps {
  incomeType: number | undefined
  data: IncomeDetailsFrontendValues
  defaultCurrency: CommonIdAndNameType
  companyVats: CompanyVatType[]
  isNotEditable: boolean
}

export function createInitialValues({
  incomeType,
  data,
  defaultCurrency,
  companyVats,
  isNotEditable,
}: IncomeCreateInitialValuesProps) {
  // no details data
  if (__isEmpty(data)) {
    const initialValues: IncomeDetailsFormInitialValues = {
      ...EMPTY_INCOME_VALUES,
      exchange_rate: getDecimal(1),
    }
    if (incomeType) {
      initialValues.income_type = incomeType
    }
    if (defaultCurrency) {
      initialValues.currency = defaultCurrency.id
    }
    if (incomeType === INCOME_TYPE_CASH_REGISTER_VOUCHER) {
      initialValues.gross_amount = getDecimal(0)
      initialValues.net_amount = getDecimal(0)
      initialValues.vat_amount = getDecimal(0)
      initialValues.assignments = getCompanyVatsAssignments(companyVats)
    }
    return initialValues
  }

  // remove planned_payment_date from detailsData, to avoid unexpected update
  // when save form changes
  // TODO check: PaymentDatePicker will update planned_payment_date independently

  // copy only relevant properties from backend data as form values
  const detailsData = __mapValues(EMPTY_INCOME_VALUES, function (value, key) {
    if (typeof data[key as keyof IncomeDetailsFrontendValues] !== 'undefined') {
      return data[key as keyof IncomeDetailsFrontendValues]
    }
    return value
  }) as IncomeDetailsFormInitialValues

  //! IMPORTANT copy id - without this can not update existing income
  detailsData.id = data.id

  if (!isNotEditable && (data.exchange_rate ?? null) === null) {
    detailsData.exchange_rate = getDefaultExchangeRateValueByCurrency({
      exchangeRate: data.exchange_rate,
      currency: detailsData.currency,
      defaultCurrency: defaultCurrency.id,
    })
  }

  // checking accounting period
  if (incomeType !== INCOME_TYPE_CASH_REGISTER_VOUCHER && data.accounting_period_start != null) {
    detailsData.need_accounting_period = true
  }

  // checking planned payment date
  if (incomeType === INCOME_TYPE_INVOICE && data.planned_payment_date != null) {
    detailsData.reschedule_payment = true
  }

  if (data.assignments.length === 0) {
    // TODO Do we really need these path?
    // always show at least 1 assignment
    if (incomeType === INCOME_TYPE_CASH_REGISTER_VOUCHER) {
      detailsData.assignments = getCompanyVatsAssignments(companyVats)
    } else {
      // do not need empty assignment in list here because BE will autogenerate it
      detailsData.assignments = []
    }
  }

  // income details form - map partner_id into form values
  if (incomeType !== INCOME_TYPE_CASH_REGISTER_VOUCHER && data.partner) {
    detailsData.partner_id = data.partner.id
  }

  return detailsData
}

// TODO move to common helpers
export function readFile(fileReference: Blob): Promise<{ url: string; file: Blob }> {
  return new Promise((resolve, reject) => {
    if (fileReference) {
      const reader = new FileReader()

      reader.onload = event => {
        resolve({ url: event.target?.result as string, file: fileReference })
      }
      reader.onerror = () => {
        reject('FileReader error')
      }

      reader.readAsDataURL(fileReference)
    } else {
      reject('Missing file reference')
    }
  })
}
