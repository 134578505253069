import React from 'react'
import PropTypes from 'prop-types'

import { INVOICE_ORIGINS } from '@constants'

import { IntegrationBlock } from './IntegrationBlock'
import { NavPreviewBlock } from './NavPreviewBlock'
import { ProcessedReasonBlock } from './ProcessedReasonBlock'

interface NotEditableReasonProps {
  hasArtifact: boolean
  isProcessedInvoice: boolean
  origin: InvoiceOrigin | undefined
  readOnly: boolean
  variant?: 'expense' | 'revenue'
}

export function NotEditableReason({
  hasArtifact,
  isProcessedInvoice,
  origin,
  readOnly,
  variant = 'expense',
}: NotEditableReasonProps) {
  if (isProcessedInvoice) {
    return <ProcessedReasonBlock readOnly={readOnly} origin={origin} />
  }

  // status based on origin
  switch (origin) {
    case INVOICE_ORIGINS.BILLINGO:
    case INVOICE_ORIGINS.SZAMLAZZ:
      return <IntegrationBlock origin={origin} readOnly={readOnly} variant={variant} />
    case INVOICE_ORIGINS.NAV:
    case INVOICE_ORIGINS.NOSZ:
      return <NavPreviewBlock hasArtifact={hasArtifact} origin={origin} />
    default:
      return null
  }
}

NotEditableReason.propTypes = {
  hasArtifact: PropTypes.bool.isRequired,
  isProcessedInvoice: PropTypes.bool.isRequired,
  origin: PropTypes.oneOf(Object.values(INVOICE_ORIGINS)).isRequired as React.Validator<InvoiceOrigin>,
  readOnly: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['revenue', 'expense']),
}
