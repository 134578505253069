import React from 'react'

import { Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import {
  ReactHookFormCheckboxField,
  ReactHookFormConditionalField,
  ReactHookFormDateField,
} from '@components/ui/FormElements'

import { SPACING } from '@oldComponents/forms/DetailsForm/styles'
import messages from '../messages'

const StyledReactHookFormCheckboxField = styled(ReactHookFormCheckboxField)`
  height: 62px;
  justify-content: flex-end;
`

interface AccountingPeriodFieldsProps {
  disabled: boolean
  isVisible?: boolean
}

export function AccountingPeriodFields({ disabled, isVisible = false }: AccountingPeriodFieldsProps) {
  const { formatMessage } = useIntl()
  return (
    <Grid container spacing={SPACING}>
      <Grid item xs={4}>
        <StyledReactHookFormCheckboxField
          name="need_accounting_period"
          label={formatMessage(messages.accountingPeriodCheckboxLabel)}
          disabled={disabled}
        />
      </Grid>
      <ReactHookFormConditionalField isVisible={isVisible} name="need_accounting_period">
        <Grid item xs={4}>
          <ReactHookFormDateField
            disabled={disabled}
            label={formatMessage(messages.accountingStartDateLabel)}
            name="accounting_period_start"
            placeholder={formatMessage(messages.dateFieldPlaceholder)}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <ReactHookFormDateField
            disabled={disabled}
            label={formatMessage(messages.accountingEndDateLabel)}
            name="accounting_period_end"
            placeholder={formatMessage(messages.dateFieldPlaceholder)}
            required
          />
        </Grid>
      </ReactHookFormConditionalField>
    </Grid>
  )
}
