import React from 'react'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { partnersActions } from '@services'

import { bindActionToPromise, customFilterOption, isFieldHighlighted } from '@helpers'

import { usePromptTextCreator } from '@hooks'

import { useIsValidNewOption } from '@components/ui/FormElements/useIsValidNewOption'

import { COMPONENT_OVERRIDES } from './componentOverrides'
import { generateOption } from './helpers'
import { PartnerOptionData, PartnerSearchFieldProps } from './types'
import { useRecommendation } from './useRecommendation'

import { formStyles, selectFormStyles } from '@styles'
import { formSelectMessages } from '@messages'

const useStyles = makeStyles(formStyles)

const START_TO_SEARCH_CHAR_LIMIT = 2

function getOptionLabel(option: PartnerOptionData) {
  return option.name
}

function getOptionValue(option: PartnerOptionData) {
  return String(option.id)
}

function getNewOptionData(inputValue: string, optionLabel: React.ReactNode) {
  return {
    id: inputValue,
    name: optionLabel,
  } as PartnerOptionData
}

function getValue(
  value: Nullable<PartnerOptionData['id']>,
  options: PartnerOptionData[]
): PartnerOptionData | undefined {
  if (!value) {
    return
  }
  return options.find(({ id }) => id === value)
}

function PurePartnerSearchField({
  className,
  name,
  defaultValue,
  disabled,
  label,
  recommendationConfig,
  searchPartners,
  onCreate,
  required = false,
  highlighted = false,
}: PartnerSearchFieldProps) {
  const { formatMessage } = useIntl()
  const {
    control,
    formState: { isSubmitting },
    setValue,
  } = useFormContext<IncomeDetailsFormInitialValues>()
  const {
    field: { onChange, value, ...fieldProps },
    fieldState: { error },
  } = useController({ name, control })
  const [options, setOptions] = React.useState<PartnerOptionData[]>([])

  const classes = useStyles()
  const promptTextCreator = usePromptTextCreator()
  const isValidNewOption = useIsValidNewOption('name')
  const { recommendations: recommendedOptionGroup, clear: clearRecommendation } =
    useRecommendation(recommendationConfig)

  React.useEffect(() => {
    if (defaultValue?.id) {
      setOptions([generateOption(defaultValue)])
    }
  }, [defaultValue])

  const handleChange = React.useCallback(
    option => {
      const newValue = option.id
      if (newValue !== value) {
        onChange(newValue)

        // fill partner fields in form
        setValue('partner_name', option.name || '', { shouldValidate: true })
        setValue('partner_account_number', option.account_number || '')
        setValue('partner_tax_number', option.tax_number || '') // TODO formatted value, should we keep or parse it?
        setValue('partner_city', option.original_address.city || '')
        setValue('partner_country', option.original_address.country || '')
        setValue('partner_zip_code', option.original_address.zip_code || '')
        setValue('partner_address', option.original_address.address || '')
        // setValue('partner_is_kata_subject', option.is_kata_subject || '')
      }
    },
    [onChange, value, setValue]
  )

  const onCreateSuccess = React.useCallback(
    partner => {
      const newPartnerOption = generateOption(partner)
      clearRecommendation(partner.name)
      setOptions([...options, newPartnerOption])
      handleChange(newPartnerOption)
    },
    [handleChange, options, setOptions, clearRecommendation]
  )

  const onCreateOptionHandler = React.useCallback(
    name => {
      onCreate({ name }, onCreateSuccess)
    },
    [onCreate, onCreateSuccess]
  )

  const onChangeHandler = React.useCallback(
    (option, { action }) => {
      if (action === 'select-option') {
        // check "prefix" option:
        // truthy: new recommendation call onCreate
        // falsy - existing recommendation call onChange
        // simple options has no prefix
        if (option?.prefix) {
          // [true, false, null] === false
          onCreateOptionHandler(option.name)
        } else {
          // (creatable) select without or existing recommendations
          handleChange(option)
        }
      }
    },
    [onCreateOptionHandler, handleChange]
  )

  //* OPTIONS
  const defaultOptions = React.useMemo(() => {
    const results = []

    if (
      recommendedOptionGroup &&
      Array.isArray(recommendedOptionGroup.options) &&
      recommendedOptionGroup.options.length
    ) {
      results.push(recommendedOptionGroup)
    }

    if (options.length) {
      results.unshift({
        label: recommendationConfig.texts.optionsLabel,
        options,
      })
    }

    // this is not a real option, just a message, so it's not selectable (custom component will handle this)
    results.push({
      name: formatMessage(formSelectMessages.asyncNoResultsText),
      isMsg: true,
    } as PartnerOptionData)

    return results
  }, [recommendationConfig, recommendedOptionGroup, options, formatMessage])

  const loadOptions = React.useCallback(
    async (inputValue: string) => {
      const {
        texts: { optionsLabel },
      } = recommendationConfig

      if (inputValue.length >= START_TO_SEARCH_CHAR_LIMIT) {
        try {
          const partners = await searchPartners({ name: inputValue })
          const mappedPartners = partners.map(generateOption)

          if (recommendedOptionGroup && recommendedOptionGroup.options.length) {
            return [
              {
                label: optionsLabel,
                options: mappedPartners,
              },
              recommendedOptionGroup,
            ]
          }

          return [
            {
              label: optionsLabel,
              options: mappedPartners,
            },
          ]
        } catch (error) {
          console.error('Error while loading partners', error)
        }
      }

      if (recommendedOptionGroup && recommendedOptionGroup.options.length) {
        return [
          {
            label: optionsLabel,
            options: options,
          },
          recommendedOptionGroup,
        ]
      }
      return options
    },
    [options, recommendationConfig, recommendedOptionGroup, searchPartners]
  )

  const noOptionsMessage = React.useCallback(
    () => formatMessage(formSelectMessages.asyncNoResultsText),
    [formatMessage]
  )

  const loadingMessage = React.useCallback(() => formatMessage(formSelectMessages.selectLoadingText), [formatMessage])

  const isFieldDisabled = disabled || isSubmitting
  const hasError = Boolean(error)

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(className, classes.selectRoot, 'form-control', {
        'form-control-error': hasError,
      })}
      error={hasError}
      disabled={isFieldDisabled}
      required={required}
    >
      {label && (
        <InputLabel htmlFor={name} shrink className={classes.bootstrapFormLabel}>
          {label}
        </InputLabel>
      )}
      <div className={classes.selectInput}>
        <AsyncCreatableSelect
          className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
          classNamePrefix="react-select"
          instanceId={`ars-${name}`}
          inputId={name}
          isDisabled={isFieldDisabled}
          value={getValue(value, options)}
          {...fieldProps}
          placeholder={formatMessage(formSelectMessages.creatableSelectPlaceholder)}
          getNewOptionData={getNewOptionData}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions} // initially loaded
          onChange={onChangeHandler}
          onCreateOption={onCreateOptionHandler}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          noOptionsMessage={noOptionsMessage}
          loadingMessage={loadingMessage}
          isValidNewOption={isValidNewOption}
          filterOption={customFilterOption}
          formatCreateLabel={promptTextCreator}
          styles={selectFormStyles}
          components={COMPONENT_OVERRIDES}
        />
      </div>
      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  )
}

export const PartnerSearchField = connect(null, dispatch => ({
  searchPartners: bindActionToPromise(dispatch, partnersActions.searchPartners.request),
}))(PurePartnerSearchField)

PartnerSearchField.displayName = 'PartnerSearchField'
