import React from 'react'

import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import Select, { components as reactSelectComponents, GroupBase, InputProps } from 'react-select'

import { selectFormStyles } from '@styles'

function transformToSelectValue(value: Nullable<string>) {
  return value != null && value !== '' ? { label: value, value } : null
}

type Option = { label: string; value: string }

const ReactSelectInput = reactSelectComponents.Input

// customize react-select Input component to avoid hidden state (will set opacity 0 on input)
function SelectInput({ isHidden, ...props }: InputProps<Option, false, GroupBase<Option>>) {
  return <ReactSelectInput isHidden={false} {...props} />
}

const componentsOverrides = {
  Input: SelectInput,
}

const noOptionsMessage = () => null

interface RecommendationSelectProps {
  className?: string
  field: ControllerRenderProps<FieldValues, string>
  disabled?: boolean
  recommendations: string[]
  transform?: ReactHookFormTransform
}

export function RecommendationSelect({
  className,
  field: { value, onChange, onBlur },
  disabled,
  recommendations,
  transform,
}: RecommendationSelectProps) {
  const options = React.useMemo(() => recommendations.map(value => ({ value, label: value })), [recommendations])

  const onInputChangeHandler = React.useCallback(
    (value, { action }) => {
      if (action === 'input-change') {
        onChange(value === '' ? null : transform ? transform.output(value) : value)
      }
    },
    [onChange, transform]
  )

  const onChangeHandler = React.useCallback(
    (option, { action }) => {
      switch (action) {
        case 'clear':
        case 'remove-value':
          onChange(null)
          break
        case 'select-option':
          onChange(option.value)
          break
        default:
      }
    },
    [onChange]
  )

  const inputValue = value ?? ''

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      inputValue={transform ? transform.input(inputValue) : inputValue}
      onInputChange={onInputChangeHandler}
      value={transformToSelectValue(value)}
      onChange={onChangeHandler}
      onBlur={onBlur}
      options={options}
      styles={selectFormStyles}
      isClearable
      isDisabled={disabled}
      placeholder={null}
      noOptionsMessage={noOptionsMessage}
      components={componentsOverrides}
    />
  )
}
