import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { expenseListWorkerRunner, parseFiltersFromUrlWorkerRunner } from '@webWorkers'

import {
  AMPLITUDE_EVENTS,
  downloadFileWithURL,
  generateBackgroundProcessActionPayload,
  generateDownloadInitAPIPayload,
  getActiveCompanyId,
  getCursorFromUrl,
  getErrorMessage,
  getExpenseListFiltersFromStore,
  getRFFFormErrors,
  getUrlFilterOptionsFromStore,
  sendAmplitudeData,
  transformSnakeCaseObjectToCamelCase,
} from '@helpers'

import { BackgroundProcessActions, InvoiceDownloadFlow, PageChangeDirection } from '@constants'

import { FeaturePermissons, isPlanPermissionEnabled } from '@permissions'

import {
  BackgroundDownloadEmailProcess,
  BackgroundExpenseUploadProcess,
  JOB_STATUS_FINISHED,
} from '../background/process'
import dashboardActions from '../dashboard/actions'
import { bulkTagging as bulkTaggingApi, fetchTags as fetchTagsApi } from '../dashboard/api'
import filtersActions from '../filters/actions'
import quarantineActions from '../quarantine/actions'
import actions from './actions'
import * as api from './api'
import { AccountingExportBackgroundAction, DownloadBackgroundAction, ExportBackgroundAction } from './backgroundActions'

// BULK REMOVE
//* v2
function* bulkDeleteV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.DELETE_EXPENSES,
      payload,
      filters
    )
    const response = yield call(api.expensesBackgroundAction, companyId, apiPayload)
    // every selected items are removed clear selection
    if (response.data.status === JOB_STATUS_FINISHED) {
      // only trigger selection clear when no rejected items
      yield put(actions.bulkDeleteV2.success())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// export register
//* v2
function* startBulkExportV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.EXPORT_EXPENSES,
      payload,
      filters
    )
    const response = yield call(ExportBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkExportV2Saga() {
  yield call(ExportBackgroundAction.stop)
}

// advanced accounting export
function* startBulkAccountingExportV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.ACCOUNTING_EXPORT_EXPENSES,
      payload,
      filters
    )
    const response = yield call(AccountingExportBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkAccountingExportV2Saga() {
  yield call(AccountingExportBackgroundAction.stop)
}

// DOWNLOAD
function* initDownloadV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateDownloadInitAPIPayload(payload, filters)
    const response = yield call(api.initDownloadV2, companyId, apiPayload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* v2
function* startBulkDownloadV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.DOWNLOAD_EXPENSES,
      payload,
      filters
    )
    const response = yield call(DownloadBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkDownloadV2Saga() {
  yield call(DownloadBackgroundAction.stop)
}

// details
function* fetchExpenseDetailsSaga({ payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchExpenseDetails, companyId, payload)
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.VIEW_EXPENSE)
    // --amplitude tracking end
    yield put(actions.fetchExpenseDetails.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseDetails.failure(errorMsg))
  }
}
function* updateExpenseSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateExpense, companyId, payload)
    // if partner is not exists in payload skip bulkInvoiceUpdateCheck
    // and return null az bulkResponseData
    let bulkResponseData = null
    if (payload.partner_id) {
      const isEnabled = yield select(isPlanPermissionEnabled, FeaturePermissons.DETAILS_BULK_UPDATE)

      if (isEnabled) {
        const bulkResponse = yield call(api.bulkInvoiceUpdateCheck, companyId, payload)
        bulkResponseData = bulkResponse.data
      }
    }

    yield put(actions.updateExpense.success(response.data))
    yield call(resolve, {
      response: response.data,
      bulkResponse: bulkResponseData,
    })
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield put(actions.updateExpense.failure())
    yield call(reject, formErrors)
  }
}
// recommendation
function* updateExpenseWithRecommendationSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.updateExpenseWithRecommendation, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// create invoice by form
function* createExpenseSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createExpense, companyId, payload)
    yield call(resolve, { response: response.data })
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.NEW_EXPENSE_CREATED, {
      from_scan: false,
    })
    // --amplitude tracking end
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

// upload invoice
function* uploadExpenseSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    // start backgroundJob
    const response = yield call(api.uploadExpense, companyId, payload)
    let results = response.data
    // track backgroundJob
    // NOTE path1: !payload.invoice - create via drop in PDF
    // path2: need_classify true - re-upload PDF with classification
    if (!payload.invoice || payload.need_classify) {
      const response2 = yield call(BackgroundExpenseUploadProcess.start, {
        id: response.data.id,
        company_id: companyId,
        invoice_id: response.data.metadata.invoice_id,
      })
      // finish process - get expense_id from backgroundJob response
      // backgroundJob success | response.data.results.id where "id" is an "expense_id"
      results = response2.data.results
    }

    // only enter here when edit an expense with re-upload artifact
    if (payload.invoice) {
      // finish process - get expense_id from backgroundJob response
      // backgroundJob success | response.data.results.id where "id" is an "expense_id"
      const detailsResponse = yield call(api.fetchExpenseDetails, companyId, payload.invoice)
      yield put(actions.uploadExpense.success(detailsResponse.data))
    }
    // --amplitude tracking start
    if (!payload.invoice) {
      yield call(sendAmplitudeData, AMPLITUDE_EVENTS.NEW_EXPENSE_CREATED, {
        from_scan: true,
      })
    }
    // --amplitude tracking end

    yield call(resolve, results)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.uploadExpense.failure())
    yield call(reject, errorMsg)
  }
}

function* cancelUploadExpenseSaga() {
  yield call(BackgroundExpenseUploadProcess.cancel)
}

function* abortUploadExpenseSaga() {
  yield call(BackgroundExpenseUploadProcess.stop)
}

// download invoice artifact
function* downloadExpenseArtifactSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.downloadExpenseArtifact, companyId, payload)
    let response2 = response
    if (payload.download_flow !== InvoiceDownloadFlow.WITHOUT_ATTACHMENTS) {
      response2 = yield call(BackgroundDownloadEmailProcess.start, {
        id: response.data.id,
        company_id: companyId,
      })
    }
    // download file when it not resolved by send-in-email cancel
    if (!response2.data.send_email) {
      yield call(downloadFileWithURL, response2)
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* abortDownloadExpenseArtifactSaga() {
  yield call(BackgroundDownloadEmailProcess.stop)
}

// remove invoice artifact
function* removeExpenseArtifactSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removeExpenseArtifact, companyId, payload)
    yield put(actions.removeExpenseArtifact.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.removeExpenseArtifact.failure())
    yield call(reject, errorMsg)
  }
}
// single status change
function* expenseStatusChangeSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.expenseStatusChange, companyId, payload)
    yield put(actions.expenseStatusChange.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}
// bulk status change

//* v2
function* bulkAccountingV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.TOGGLE_BOOK_EXPENSES,
      payload,
      filters
    )
    const response = yield call(api.expensesBackgroundAction, companyId, apiPayload)
    if (response.data.status === JOB_STATUS_FINISHED) {
      yield put(actions.bulkAccountingV2.success())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* v2
function* bulkFilingV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.FILE_EXPENSES, payload, filters)
    const response = yield call(api.expensesBackgroundAction, companyId, apiPayload)
    //! call failure when need to update list, call succes when need to update items in store only
    if (payload.isAllSelected) {
      yield put(actions.bulkFilingV2.failure())
    } else {
      yield put(actions.bulkFilingV2.success(response.data.results))
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* filingSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.FILE_EXPENSES, payload, {})
    const response = yield call(api.expensesBackgroundAction, companyId, apiPayload)
    yield put(actions.expenseFiling.success(response.data.results))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// single approve
function* expenseApproveSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.expenseApprove, companyId, payload)
    yield put(actions.expenseApprove.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// bulk approve
//* v2
function* bulkApproveV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.TOGGLE_APPROVE_EXPENSES,
      payload,
      filters
    )
    yield call(api.expensesBackgroundAction, companyId, apiPayload)
    yield put(actions.bulkApproveV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// bulk upload
function* uploadMultipleExpenseSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(api.uploadMultipleExpense, payload)
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.NEW_MULTI_EXPENSE_CREATED)
    // --amplitude tracking end
    yield call(resolve)
  } catch (error) {
    // do not parse error here, it's handled in the useFileDropzone hook
    yield call(reject, error)
  }
}

// user viewed new invoice
function* userViewExpenseSaga({ payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.userViewExpense, companyId, payload)
  } catch (error) {
    // do nothing
  }
}

// DUPLICATED EXPENSE
function* checkExpenseDuplicationSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.checkExpenseDuplication, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* V2
function* deleteExpenseDuplicationSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.deleteExpenseDuplication, companyId, payload)
    yield put(actions.deleteExpenseDuplication.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* removeExpenseSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removeExpense, companyId, payload)
    yield put(actions.removeExpense.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// v2 - duplication modal
function* deleteExpenseFromListSaga({ payload: { needToUpdateList, ...apiPayload }, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removeExpense, companyId, apiPayload)
    // only need this when merge called from list view
    if (needToUpdateList === 'expense') {
      yield put(quarantineActions.triggerExpenseUpdateV2.request())
    }
    if (needToUpdateList === 'quarantine') {
      yield put(quarantineActions.triggerQuarantineUpdateV2.request())
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//   expense types
function* fetchExpenseTypesSaga({ payload }) {
  try {
    const response = yield call(api.fetchExpenseTypes, payload) // payload == companyId
    yield put(actions.fetchExpenseTypes.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseTypes.failure(errorMsg))
  }
}

function* createExpenseTypeSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.createExpenseType, payload) // company exists in payload
    yield put(actions.createExpenseType.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// EVENTS LOG
function* fetchEventsLogSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchEventsLog, companyId, payload.documentId)
    yield call(resolve, response.data.map(transformSnakeCaseObjectToCamelCase))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// CATEGORIZATION
//* v2
function* bulkCategorizationV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.bulkCategorization, companyId, payload)
    yield put(actions.bulkCategorizationV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK TAGGING
//* v2
function* bulkTaggingV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(bulkTaggingApi, companyId, payload)
    yield put(actions.bulkTaggingV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK SYNC INVOICE ARTIFACT
//* v2
function* bulkSyncV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(payload.action, payload, filters)
    yield call(api.expensesBackgroundAction, companyId, apiPayload)
    yield put(actions.bulkSyncV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// CUSTOM VALIDATOR
function* invoiceNumberCheckSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.invoiceNumberCheck, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* initExpenseListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    const companyId = yield select(getActiveCompanyId)
    // gather expense types and tags as we're going to need them for mapping purposes for the list
    const [expenseTypesResponse, tagsResponse] = yield all([
      call(api.fetchExpenseTypes, companyId),
      call(fetchTagsApi, companyId),
    ])

    yield put(actions.fetchExpenseTypes.success(expenseTypesResponse.data))
    yield put(dashboardActions.fetchTags.success(tagsResponse.data))

    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* >> sync from url to store: call worker
    const { filters, params, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initExpenseListFiltersFromUrl.request({ filters })),
      put(filtersActions.initExpenseListParamsFromUrl.request(params)),
    ])

    yield call(resolve, validationLevel)
    yield all([put(actions.fetchExpenseListV2.request({ navigate })), put(actions.fetchExpenseCharts.request())])
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    yield put(actions.fetchExpenseListV2.failure(errorMsg))
  }
}

//* V2 API
function* fetchExpenseListV2Saga({ payload: { navigate } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore, { withCursor: true })
    const { expenseTypes, tags } = yield select(({ dashboard: { tags }, expense: { expenseTypes } }) => ({
      expenseTypes: expenseTypes.data,
      tags: tags.data,
    }))
    // start fetching expenses
    let listResponse = yield call(api.fetchExpenseListV2, companyId, filters)

    // drop cursor and refetch list when actual cursor return zero results
    const isCursorDropped = filters.cursor && listResponse.data.results.length === 0
    let previousCursor = null
    if (isCursorDropped) {
      if (listResponse.data.previous) {
        // when previous list is exists
        previousCursor = getCursorFromUrl(listResponse.data.previous)
        listResponse = yield call(api.fetchExpenseListByPagingV2, listResponse.data.previous)
      } else {
        // clear cursor when no previous list
        const { cursor, ...newFilters } = filters
        listResponse = yield call(api.fetchExpenseListV2, companyId, newFilters)
      }
    }

    //* call worker
    const workerResults = yield call(expenseListWorkerRunner, {
      expenses: listResponse.data.results,
      expenseTypes,
      tags,
    })

    yield put(
      actions.fetchExpenseListV2.success({
        data: workerResults,
        isCursorDropped,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
        previousCursor,
      })
    )
    //* << sync from store to url
    const storedFilters = yield select(getExpenseListFiltersFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))

    // set expense count if there is only one page otherwise we set to 0 and we fetch the count in the PageCounter component
    const count = !listResponse.data.next && !listResponse.data.previous ? listResponse.data.results.length : 0
    yield put(actions.fetchExpenseCountV2.success({ count }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseListV2.failure(errorMsg))
  }
}

function* fetchExpenseListByPagingV2Saga({ payload: { url, direction } }) {
  try {
    const { expenseTypes, tags } = yield select(({ dashboard: { tags }, expense: { expenseTypes } }) => ({
      expenseTypes: expenseTypes.data,
      tags: tags.data,
    }))
    const listResponse = yield call(api.fetchExpenseListByPagingV2, url)

    //* call worker
    const workerResults = yield call(expenseListWorkerRunner, {
      expenses: listResponse.data.results,
      expenseTypes,
      tags,
    })

    yield put(
      actions.fetchExpenseListByPagingV2.success({
        data: workerResults,
        direction,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseListByPagingV2.failure(errorMsg))
  }
}

// handle paging on details with v2 data
function* fetchExpenseDetailsByPagingV2Saga({ payload: { url, isNext }, meta: { resolve, reject } }) {
  try {
    const listResponse = yield call(api.fetchExpenseListByPagingV2, url)

    // skip worker because do not need to map these values here, do not show this list for user
    const results = yield call(
      expenses =>
        expenses.map(({ expenseTypeIds, tagIds, simpleTagIds, ...expense }) => ({
          ...expense,
          expenseTypes: [],
          simpleTags: [],
          tags: [],
        })),
      listResponse.data.results
    )

    yield put(
      actions.fetchExpenseListByPagingV2.success({
        direction: isNext ? PageChangeDirection.NEXT : PageChangeDirection.PREV,
        data: results,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
    // return the id of the next expense to fetch
    const nextId = isNext ? results[0].id : results[results.length - 1].id
    yield call(resolve, nextId)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    const errorResponseData = error?.response?.data || {}
    yield call(reject, { data: errorResponseData, msg: errorMsg })
  }
}

function* fetchExpenseCountV2Saga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    // start fetching expenses
    const countResponse = yield call(api.fetchExpenseCountV2, companyId, filters)
    yield put(actions.fetchExpenseCountV2.success({ count: countResponse.data.count }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseListV2.failure(errorMsg))
  }
}

//* When we do not need to fetch expense types and tags separately from the backend, only the list data (ie. when reordering)
//! Note: we need to think about how to handle multiple users' background changes, if a new tag/expense type is created while the current user does not have it
function* fetchExpenseListV2SimpleSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)
    const { expenseTypes, tags } = yield select(({ dashboard: { tags }, expense: { expenseTypes } }) => ({
      expenseTypes: expenseTypes.data,
      tags: tags.data,
    }))

    // start fetching expenses
    const listResponse = yield call(api.fetchExpenseListV2, companyId, filters)
    //* call worker
    const workerResults = yield call(expenseListWorkerRunner, {
      expenses: listResponse.data.results,
      expenseTypes,
      tags,
    })

    yield put(
      actions.fetchExpenseListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )

    // set expense count if there is only one page otherwise we set to 0 and we fetch the count in the PageCounter component
    const count = !listResponse.data.next && !listResponse.data.previous ? listResponse.data.results.length : 0
    yield put(actions.fetchExpenseCountV2.success({ count }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseListV2.failure(errorMsg))
  }
}

function* fetchExpenseChartsSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getExpenseListFiltersFromStore)

    // start fetching expenses
    const listResponse = yield call(api.fetchExpenseCharts, companyId, filters)
    yield put(actions.fetchExpenseCharts.success(listResponse.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchExpenseCharts.failure(errorMsg))
  }
}

//* V2
function* initDuplicationSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.initDuplication, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* advanced accounting
function* callAdvancedAccountingToggleSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.callUrlByPostMethod, payload)
    yield put(actions.callAdvancedAccountingToggle.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* callUnlockAccountingSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.callUrlByPostMethod, payload)
    yield put(actions.callUnlockAccounting.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* callExcludeFromAccountingSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.callUrlByPostMethod, payload)
    yield put(actions.callExcludeFromAccountingToggle.success(response.data))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchLastFilingNumberSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchLastFilingNumber, companyId)
    yield call(resolve, response.data.last)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  // list page controller init
  yield takeLatest(actions.initExpenseListPageLoad.REQUEST, initExpenseListPageLoadSaga)
  // processes
  yield takeLatest(actions.uploadExpense.REQUEST, uploadExpenseSaga)
  yield takeLatest(actions.cancelUploadExpenseProcess.REQUEST, cancelUploadExpenseSaga)
  yield takeLatest(actions.abortUploadExpense.REQUEST, abortUploadExpenseSaga)
  yield takeLatest(actions.downloadExpenseArtifact.REQUEST, downloadExpenseArtifactSaga)
  yield takeLatest(actions.abortDownloadExpenseArtifact.REQUEST, abortDownloadExpenseArtifactSaga)
  // expense types
  yield takeLatest(actions.fetchExpenseTypes.REQUEST, fetchExpenseTypesSaga)
  yield takeLatest(actions.createExpenseType.REQUEST, createExpenseTypeSaga)
  yield takeLatest(actions.fetchExpenseDetails.REQUEST, fetchExpenseDetailsSaga)
  yield takeLatest(actions.updateExpense.REQUEST, updateExpenseSaga)
  yield takeLatest(actions.createExpense.REQUEST, createExpenseSaga)
  // recommendation
  yield takeLatest(actions.updateExpenseWithRecommendation.REQUEST, updateExpenseWithRecommendationSaga)
  yield takeLatest(actions.removeExpenseArtifact.REQUEST, removeExpenseArtifactSaga)
  // multi upload
  yield takeLatest(actions.uploadMultipleExpense.REQUEST, uploadMultipleExpenseSaga)
  // status change
  yield takeLatest(actions.expenseStatusChange.REQUEST, expenseStatusChangeSaga)
  // approve
  yield takeLatest(actions.expenseApprove.REQUEST, expenseApproveSaga)

  yield takeLatest(actions.userViewExpense.REQUEST, userViewExpenseSaga)
  // expense duplication
  yield takeLatest(actions.checkExpenseDuplication.REQUEST, checkExpenseDuplicationSaga)
  yield takeLatest(actions.removeExpense.REQUEST, removeExpenseSaga)
  // events log
  yield takeLatest(actions.fetchEventsLog.REQUEST, fetchEventsLogSaga)
  // validation engine - custom validator
  yield takeLatest(actions.invoiceNumberCheck.REQUEST, invoiceNumberCheckSaga)
  //* V2 service
  yield takeLatest(actions.fetchExpenseCountV2.REQUEST, fetchExpenseCountV2Saga)
  yield takeLatest(actions.fetchExpenseListV2.REQUEST, fetchExpenseListV2Saga)
  yield takeLatest(actions.fetchExpenseListByPagingV2.REQUEST, fetchExpenseListByPagingV2Saga)
  yield takeLatest(actions.fetchExpenseDetailsByPagingV2.REQUEST, fetchExpenseDetailsByPagingV2Saga)
  yield takeLatest(
    [
      actions.updateOrderV2.REQUEST,
      actions.updateRowsPerPageV2.REQUEST,
      filtersActions.toggleExpenseListDateFilter.REQUEST,
      filtersActions.updateExpenseListFilters.REQUEST,
      filtersActions.resetExpenseListFilters.REQUEST,
      quarantineActions.triggerExpenseUpdateV2.REQUEST,
      actions.bulkTaggingV2.SUCCESS,
      actions.bulkCategorizationV2.SUCCESS,
      actions.bulkApproveV2.SUCCESS,
      actions.bulkAccountingV2.SUCCESS,
      actions.triggerExpenseListUpdate.REQUEST,
      actions.bulkFilingV2.FAILURE, //! used as secondary success action in this case
      actions.bulkSyncV2.SUCCESS,
      actions.bulkDeleteV2.SUCCESS,
      actions.resetPagination.REQUEST,
    ],
    fetchExpenseListV2SimpleSaga
  )
  yield takeLatest(
    [
      filtersActions.updateExpenseListFilters.REQUEST,
      filtersActions.resetExpenseListFilters.REQUEST,
      filtersActions.toggleExpenseListDateFilter.REQUEST,
      actions.fetchExpenseCharts.REQUEST,
      quarantineActions.triggerExpenseUpdateV2.REQUEST,
      actions.bulkCategorizationV2.SUCCESS,
      actions.triggerExpenseListUpdate.REQUEST,
      actions.bulkDeleteV2.SUCCESS,
    ],
    fetchExpenseChartsSaga
  )
  yield takeLatest(actions.initDuplication.REQUEST, initDuplicationSaga)
  // duplication v2
  yield takeLatest(actions.deleteExpenseDuplication.REQUEST, deleteExpenseDuplicationSaga)
  yield takeLatest(actions.deleteExpenseFromList.REQUEST, deleteExpenseFromListSaga)
  // tagging v2
  yield takeLatest(actions.bulkTaggingV2.REQUEST, bulkTaggingV2Saga)
  // categorization v2
  yield takeLatest(actions.bulkCategorizationV2.REQUEST, bulkCategorizationV2Saga)
  // approve v2
  yield takeLatest(actions.bulkApproveV2.REQUEST, bulkApproveV2Saga)
  // status change v2
  yield takeLatest(actions.bulkAccountingV2.REQUEST, bulkAccountingV2Saga)
  // filing v2
  yield takeLatest(actions.bulkFilingV2.REQUEST, bulkFilingV2Saga)
  yield takeLatest(actions.expenseFiling.REQUEST, filingSaga)
  // snyc v2
  yield takeLatest(actions.bulkSyncV2.REQUEST, bulkSyncV2Saga)
  // export v2
  yield takeLatest(actions.startBulkExportV2.REQUEST, startBulkExportV2Saga)
  yield takeLatest(actions.stopBulkExportV2.REQUEST, stopBulkExportV2Saga)
  // delete v2
  yield takeLatest(actions.bulkDeleteV2.REQUEST, bulkDeleteV2Saga)
  // download v2
  yield takeLatest(actions.initDownloadV2.REQUEST, initDownloadV2Saga)
  yield takeLatest(actions.startBulkDownloadV2.REQUEST, startBulkDownloadV2Saga)
  yield takeLatest(actions.stopBulkDownloadV2.REQUEST, stopBulkDownloadV2Saga)
  // advanced accounting
  yield takeLatest(actions.callAdvancedAccountingToggle.REQUEST, callAdvancedAccountingToggleSaga)
  yield takeLatest(actions.callUnlockAccounting.REQUEST, callUnlockAccountingSaga)
  yield takeLatest(actions.callExcludeFromAccountingToggle.REQUEST, callExcludeFromAccountingSaga)
  // advanced accounting export
  yield takeLatest(actions.startBulkAccountingExportV2.REQUEST, startBulkAccountingExportV2Saga)
  yield takeLatest(actions.stopBulkAccountingExportV2.REQUEST, stopBulkAccountingExportV2Saga)
  yield takeLatest(actions.fetchLastFilingNumber.REQUEST, fetchLastFilingNumberSaga)
}
