import React from 'react'

import __isEmpty from 'lodash/isEmpty'

import { getDecimal } from '@helpers/common'

import { IncomeManualFormValues } from './types'

export function useFormInitialValues(
  details: IncomeDetailsFrontendValues,
  defaultCurrencyId: number,
  incomeType: number
): IncomeManualFormValues {
  return React.useMemo(() => {
    if (__isEmpty(details)) {
      return {
        accounting_period_end: '',
        accounting_period_start: '',
        currency: defaultCurrencyId,
        exchange_date: null,
        exchange_rate: getDecimal(1),
        fulfilled_at: '',
        income_type: incomeType,
        need_accounting_period: false,
        net_amount: '',
        partner_account_number: '',
        partner_address: '',
        partner_city: '',
        partner_country: '',
        partner_id: '',
        partner_name: '',
        partner_tax_number: '',
        partner_zip_code: '',
        simple_tags: [],
        tags: [],
      }
    }
    return {
      accounting_period_end: details.accounting_period_end || '',
      accounting_period_start: details.accounting_period_start || '',
      currency: details.currency || defaultCurrencyId,
      exchange_date: details.exchange_date || null,
      exchange_rate: details.exchange_rate || getDecimal(1),
      fulfilled_at: details.fulfilled_at || '',
      id: details.id,
      income_type: incomeType,
      need_accounting_period: details.accounting_period_start != null,
      net_amount: details.net_amount || '',
      partner_account_number: details.partner_account_number || '',
      partner_address: details.partner_address || '',
      partner_city: details.partner_city || '',
      partner_country: details.partner_country || '',
      partner_id: details.partner?.id || '',
      partner_name: details.partner_name || '',
      partner_tax_number: details.partner_tax_number || '',
      partner_zip_code: details.partner_zip_code || '',
      simple_tags: details.simple_tags,
      tags: details.tags,
    }
  }, [defaultCurrencyId, details, incomeType])
}
